<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text>
        <v-form ref="form">
          <template>
            <v-text-field
              v-model="settings.email"
              label="メールアドレス"
              readonly
              prepend-inner-icon="feather-mail"
              class="mb-3"
            />

            <v-text-field
              v-if="isAccountExists === 0"
              v-model="settings.fullname"
              :label="fullname_label"
              :hint="fullname_hint"
              :rules="nameRules"
              required
              prepend-inner-icon="feather-user"
              class="mb-3"
            />

            <v-text-field
              v-model="settings.password"
              :label="password_label"
              :rules="passwordRules"
              validate-on-blur
              :hint="$t('message.password_validation')"
              type="password"
              required
              prepend-inner-icon="feather-lock"
              class="mb-3"
            />

            <v-text-field
              v-if="isAccountExists === 0"
              v-model="settings.confirm_password"
              label="確認用パスワード"
              :rules="passwordRules_2"
              validate-on-blur
              :hint="$t('message.password_validation')"
              type="password"
              required
              prepend-inner-icon="feather-lock"
              class="mb-3"
            />
          </template>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="onClickNext"> 次へ </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '',
    },
    isAccountExists: {
      type: Number,
      default: 1,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fullname_label: '',
      fullname_hint: '',
      password_label: '',
      confirmpassword_label: '',
      nameRules: [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          this.$t('message.form_input_required'),
        (v) =>
          v.length <= 100 || '100' + this.$t('message.character_max_length'),
      ],
      passwordRules: [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v &&
            v.length >= 8 &&
            this.isIncludeUpper(v) &&
            this.isIncludeLower(v) &&
            this.isIncludeNumber(v) &&
            this.isIncludeSymbol(v)) ||
          this.$t('message.password_validation'),
        (v) =>
          (v && v.length <= 50) ||
          '50' + this.$t('message.character_max_length'),
      ],
      passwordRules_2: [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v &&
            v.length >= 8 &&
            this.isIncludeUpper(v) &&
            this.isIncludeLower(v) &&
            this.isIncludeNumber(v) &&
            this.isIncludeSymbol(v)) ||
          this.$t('message.password_validation'),
        (v) =>
          (v && v.length <= 50) ||
          '50' + this.$t('message.character_max_length'),
        (v) =>
          (v && v == this.settings.password) ||
          this.$t('message.error_password_and_confirm_not_same'),
      ],
    }
  },
  mounted() {
    this.fullname_label = this.$t('message.signup_confirm_full_name')
    this.fullname_hint = this.$t('message.signup_confirm_full_name_hint')
    this.password_label = this.$t('message.signup_confirm_password')
    this.confirmpassword_label = this.$t(
      'message.signup_confirm_confirmpassword'
    )
    if (this.isAccountExists === 1) {
      this.passwordRules.splice(1, 1)
    }
  },
  methods: {
    isIncludeUpper(text) {
      for (let s of text) {
        if (s.match(/^[A-Z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeLower(text) {
      for (let s of text) {
        if (s.match(/^[a-z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeNumber(text) {
      for (let s of text) {
        if (!isNaN(s)) {
          return true
        }
      }
      return false
    },
    isIncludeSymbol(text) {
      let reg = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g)
      if (reg.test(text)) {
        return true
      }
      return false
    },
    validate() {
      return this.$refs.form.validate()
    },
    onClickNext() {
      const isValid = this.validate()
      if (isValid) {
        this.$emit('click:next')
      }
    },
  },
}
</script>
