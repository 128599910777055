<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text>
        <v-form ref="form">
          <template>
            <v-text-field
              v-model="settings.organization_name"
              label="会社名 / 個人名 / 屋号"
              readonly
              prepend-inner-icon="feather-home"
              class="mb-3"
            />

            <v-text-field
              label="代表電話番号"
              hint="例: 03-1234-5678"
              :rules="inputRules"
              validate-on-blur
              :value="phonenumberWithhyphen"
              required
              prepend-inner-icon="feather-phone"
              class="mb-3"
              @input="settings.telphoneNum = $event.replace(/-/g, '')"
            />

            <v-text-field
              v-model="settings.zipcode"
              v-mask="'###-####'"
              label="郵便番号"
              hint="例: 123-4567"
              :rules="inputRules"
              validate-on-blur
              required
              prepend-inner-icon="feather-map-pin"
              class="mb-3"
            />

            <v-text-field
              v-model="settings.companyAddress"
              label="本社住所"
              hint="例: 東京都千代田区大手町1-6-1"
              :rules="inputRules"
              validate-on-blur
              required
              prepend-inner-icon="feather-map-pin"
              class="mb-3"
            />

            <v-select
              v-model="settings.typeOfBussiness"
              label="業種(任意）"
              :items="itemsIndustryList"
              item-text="name"
              item-value="id"
              validate-on-blur
              prepend-inner-icon="feather-briefcase"
              class="mb-3"
            />
          </template>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('click:previous')"> 戻る </v-btn>
      <v-spacer />
      <v-btn :loading="loading" color="primary" @click="onClickNext">
        次へ
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'

export default {
  props: {
    height: {
      type: String,
      default: '',
    },
    itemsIndustryList: {
      type: Array,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputRules: [
        (v) =>
          v.length < 255 || '255' + this.$t('message.character_max_length'),
        (v) => !!v || '※この項目は必須です',
      ],
      loading: false,
    }
  },
  computed: {
    phonenumberWithhyphen() {
      return new AsYouType('JP').input(this.settings.telphoneNum)
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    onClickNext() {
      const isValid = this.validate()
      if (isValid) {
        this.loading = true
        this.$emit('click:next')
      }
    },
  },
}
</script>
