<template>
  <div>
    <div v-if="activated">
      <v-container
        fluid
        py-0
        class="blue"
        style="position: fixed; overflow: scroll; height: 100vh"
      >
        <v-row align="center" justify="center" style="height: 100vh">
          <v-card class="pa-5 ma-5" width="480">
            <v-card-title class="d-flex justify-center"> sweeep </v-card-title>
            <v-card-subtitle class="d-flex justify-center">
              アカウント登録
            </v-card-subtitle>
            <v-card-text>
              <p>{{ $t('message.signup_confirm_account_activated') }}</p>
              <v-btn block color="blue" dark @click="goToLogin">
                こちらからログインください
              </v-btn>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </div>
    <div v-if="!activated">
      <confirm-dialog ref="confirmDialog" size="medium" />
      <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            個人情報
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="e1 > 2" step="2">
            会社情報
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <user-setting-wizard
              :height="getScrollHeight()"
              :is-account-exists="isAccountExists"
              :settings="userSettings"
              @click:next="onClickNext"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <company-setting-wizard
              ref="companySetting"
              :height="getScrollHeight()"
              :items-industry-list="itemsIndustryList"
              :settings="companySettings"
              @click:previous="onClickPrevious"
              @click:next="submit"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import UserSettingWizard from './wizard/UserSettingWizard'
import CompanySettingWizard from './wizard/CompanySettingWizard'

export default {
  components: {
    UserSettingWizard,
    CompanySettingWizard,
  },
  data() {
    return {
      e1: 1,
      activated: false,
      isAccountExists: 1,
      itemsIndustryList: [],
      userSettings: {
        email: '',
        password: '',
        confirm_password: '',
        fullname: '',
      },
      companySettings: {
        organization_name: '',
        telphoneNum: '',
        zipcode: '',
        companyAddress: '',
        typeOfBussiness: '',
      },
    }
  },
  mounted() {
    this.$refs.confirmDialog.open(
      this.$t(
        'sweeepの初期設定を行います。\n設定は管理者メニュー内で変更可能です。'
      )
    )
    try {
      let apiUrl = this.$store.getters.apiSignUpCheckCodeFunction
      let data = {
        id: this.$route.params.id,
        activation_code: this.$route.params.code,
      }
      this.$store
        .dispatch('apiCheckConfirmCode', {
          data,
          apiUrl,
        })
        .then((response) => {
          if (response.data.data.activated == true) {
            this.activated = true
          } else {
            this.isAccountExists = response.data.data.business_form
            this.userSettings.email = response.data.data.email
            this.companySettings.organization_name =
              response.data.data.organization_name
            this.itemsIndustryList = response.data.industry
          }
        })
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    getScrollHeight() {
      return 'height:calc(100vh - 190px)'
    },
    onClickNext() {
      this.e1 += 1
    },
    onClickPrevious() {
      this.e1 -= 1
    },
    submit() {
      try {
        if (this.isAccountExists == 1) {
          this.userSettings.confirm_password = this.password
        }
        if (this.password == this.confirm_password) {
          let apiUrl = this.$store.getters.apiConfirmSignUpFunction
          let data = {
            id: this.$route.params.id,
            full_name: this.userSettings.fullname,
            password: this.userSettings.password,
            telphone_number: this.companySettings.telphoneNum,
            zipcode: this.companySettings.zipcode,
            address: this.companySettings.companyAddress,
            type_of_bussiness: this.companySettings.typeOfBussiness,
            activation_code: this.$route.params.code,
          }
          this.$store
            .dispatch('apiConfirmSignUp', {
              data,
              apiUrl,
            })
            .then(
              (response) => {
                if (response.data.error) {
                  Snackbar.error(response.data.error)
                  this.$refs.companySetting.loading = false
                } else {
                  this.SignInSweeep().then(() => {
                    this.$refs.companySetting.loading = false
                  })
                }
              },
              (error) => {
                console.log(error)
              }
            )
        } else {
          this.$refs.companySetting.loading = false
          Snackbar.error(this.$t('message.signup_confrim_password_match_error'))
        }
      } catch (e) {
        console.log(e)
      }
    },
    async SignInSweeep() {
      const apiUrl = this.$store.getters.apiLoginFunction
      const message = '登録が完了しました'
      const user = {
        email: this.userSettings.email,
        password: this.userSettings.password,
        envtype: this.$store.getters.getEnvType,
        organization_name: this.companySettings.organization_name,
      }
      const next = '/initial-settings'
      const response = await this.$store.dispatch('apiSignInUser', {
        user,
        message,
        apiUrl,
        next,
      })
      return response
    },
    goToLogin() {
      try {
        this.$router.push('/session/login')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
